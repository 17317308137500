import React from 'react';

import { ItemsProvider } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import LoadMoreButton from '@common/react/components/Core/ItemsProvider/LoadMoreButton';
import { List } from '@common/typescript/objects/List';
import { fromNow } from '@common/react/utils/timeUtils';

import { Review } from '@commonTuna/react/objects/Review';

interface Props {
	reviews: List<Review> | null;
}

const Reviews: React.FC<Props> = ({ reviews }) => {
	return (
		<ItemsProvider<Review>
			items={reviews?.list}
			pagination={{ current: 1, pageSize: reviews?.list.length || 5, total: reviews?.count || 0 }}
			type="review"
			loadRequest="reviewViewSMList"
		>
			{(context) => (
				<>
					{context.state.items?.length > 0
						? (<>
							<div className="reviews">
								<ul>
									{context.state.items.map((item) => (
										<li className="review" key={item.id}>
											<h4>{item.author}</h4>
											<div>
												<span className="stars">
													{Array.from({ length: Math.round(item.rate || 0) })
														.map((_, i) => <i className="fa fa-star" key={i} aria-hidden="true" />)}
												</span>
												{fromNow(item.time)}
											</div>
											<div className="mb10">
												<p>{item.textEn}</p>
											</div>
										</li>
									))}
								</ul>
							</div>
							<div className="extra-text-block text-center" style={{ margin: 0, paddingBottom: 10 }}>
								<LoadMoreButton className="more-button more-button__big">
									More Sharell Marlitz Reviews
								</LoadMoreButton>
							</div>
						</>)
						: <div className="reviews">
							<ul>
								<li className="review" key="empty">
									<h4 style={{ marginBottom: -7 }}>No reviews yet.</h4>
								</li>
							</ul>
						</div>
					}
				</>
			)}
		</ItemsProvider>
	);
};

export default Reviews;
