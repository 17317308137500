import React from 'react';

import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import { PageImage } from '@commonTuna/react/objects/PageImage';

interface Props {
	pageImages: Array<PageImage>;
}

const SpecialsBlock: React.FC<Props> = ({ pageImages }) => {
	const pagination = {
		clickable: true,
	};

	return (
		<>
			<Swiper
				pagination={pagination}
				modules={[Pagination]}
				className="mySwiper"
				autoHeight
				observer
				observeParents
				observeSlideChildren
			>
				{pageImages.map((item) => <SwiperSlide key={item.id}>
					<ImageLazy alt={item.titleEn} width="400px" height="400px" className="slider-image" src={`/remote/${item.file.src}`} />
					<div className="slider-text">
						{item.titleEn && <h3 className="slider-name">{item.titleEn}</h3>}
						{item.textEn
							&& <p>
								{item.textEn}
							</p>
						}
					</div>
				</SwiperSlide>)}
			</Swiper>
		</>
	);
};

export default SpecialsBlock;
