import React from 'react';

import Inquiry from '@app/components/UI/Inquiry/Inquiry';

interface Props {
	position?: 'left' | 'right';
}

const HomeForm: React.FC<Props> = ({ position = 'right' }) => {
	const [open, setOpen] = React.useState(false);

	const toggle = React.useCallback(() => setOpen((prev) => !prev), []);

	return (
		<div className={`is-relative inquiry-${position}-md inquiry-${position}-${open ? 'open' : 'close'}`}>
			<Inquiry />
			<button type="button" aria-label="toggle form visible" className="arrow-button" onClick={toggle}>
				<i className={`fa fa-angle-${open ? 'right' : 'left'}`} />
			</button>
		</div>
	);
};

export default HomeForm;
