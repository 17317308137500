import React from 'react';

import { phoneFormat } from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';
import { days, getMinutesFromDuration, timeFormat } from '@common/react/utils/timeUtils';

import SimpleMap from '@app/components/UI/Map/Map';
import { Location } from '@app/objects/Location';

interface Props {
	location: Location;
}

const Contacts: React.FC<Props> = ({ location }) => {
	const link = React.useMemo(() => {
		let res = `https://maps.google.com/maps/search/?api=1&query=${location.addressEn}`;
		if (typeof window !== 'undefined') {
			if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
				res = `maps://maps.google.com/maps/search/?api=1&query=${location.addressEn}`;
			}
		}
		return res;
	}, [location]);
	const arr = React.useMemo(() =>
		[location.addressEn, location.city, location.state, location.zip].filter((str) => str), []);
	const address = arr.filter((e) => e).join(', ');

	const onClick = (e) => {
		const el = e?.target;
		if (el?.closest('.gm-control-active') || el?.classList.contains('gm-control-active')
			|| el.tagName?.toLowerCase() === 'a') {
			e.preventDefault();
		}
	};

	const wh = React.useMemo(() => {
		const wh = {};

		location.workingHours?.forEach((item) => {
			if (!wh[item.dayOfWeek]) {
				wh[item.dayOfWeek] = { ...item };
			} else {
				wh[item.dayOfWeek].startTime = +getMinutesFromDuration(item.startTime) > +getMinutesFromDuration(wh[item.dayOfWeek].startTime)
					? wh[item.dayOfWeek].startTime : item.startTime;
				wh[item.dayOfWeek].endTime = +getMinutesFromDuration(item.endTime) < +getMinutesFromDuration(wh[item.dayOfWeek].endTime)
					? wh[item.dayOfWeek].endTime : item.endTime;
			}
		});

		return wh;
	}, []);

	return (
		<div className="map-container">
			<div className="clearfix contacts__location">
				<div className="location-name clearfix">
					{/* <h3>{location.nameEn}</h3> */}
					<div className="clearfix">
						<h4 className="pull-left">
							<a
								onClick={onClick}
								title={`Open ${location.companyName} address at google maps`}
								target="_blank"
								href={link}
								rel="noreferrer"
							>
								<label>Address:</label>
								<address>{address}</address>
							</a>
						</h4>
						{location.phone && <h4 className="pull-left">
							<a href={`tel:${location.phone}`} title={`Call ${location.companyName} Clinic`}>
								<label>Phone:</label>
								{' '}
								{phoneFormat(location.phone)}
							</a>
						</h4>}
						{location.portalEmail && <h4 className="pull-left">
							<a href={`mailto:${location.portalEmail}`} title={`Send Email to ${location.companyName} Clinic`}>
								<label>Email:</label>
								{' '}
								{location.portalEmail}
							</a>
						</h4>}
					</div>
				</div>
				{location.workingHours && <div className="mr10 working-hours">
					<h4><label>Hours: </label></h4>
					<div>
						<ul className="working-hour-list">
							{
								Object.keys(wh)
									.map((key) => <li className="working-hour" key={key}>
										<div className="text-center">
											{days[wh[key].dayOfWeek]}
										</div>
										<div className="text-center">
											{timeFormat(wh[key].startTime)}
											{' - '}
											{timeFormat(wh[key].endTime)}
										</div>
									</li>)
							}
						</ul>
					</div>
				</div>}
			</div>
			<a
				onClick={onClick}
				target="_blank"
				href={link}
				rel="noreferrer"
			>
				<SimpleMap address={location.addressEn} googleApiKey={location?.company?.googleApiKey || ''} />
			</a>
		</div>
	);
};

export default Contacts;
